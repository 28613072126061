<i18n>
  {
    "es": {
      "ADDED_PICKUPPOINT": "Seleccionada tienda de recogida",
      "ERROR": "Error al añadir tienda de recogida",
      "GEOLOCATION_ICON": "Icono de geolocalización",
      "LIST_ICON": "Icono de lista",
      "SEARCH_LOCATION": "Buscar por ubicación",
      "OPEN_LIST": "Abrir lista de tiendas de recogida",
      "MAP": "Mapa",
      "LIST": "Lista",
      "PICKUP_ICON'": "Icono de localización",
    },
    "en": {
      "ADDED_PICKUPPOINT": "Store selected",
      "ERROR": "Error adding store",
      "GEOLOCATION_ICON": "Geolocation icon",
      "LIST_ICON": "List icon",
      "SEARCH_LOCATION": "Search by location",
      "OPEN_LIST": "Open stores list",
      "MAP": "Map",
      "LIST": "List",
      "PICKUP_ICON": "Location icon",
    }
  }
</i18n>

<template>
  <div>
    <ModalCourtain :visibility="visibility" @close="cancel">
      <template v-if="isMobileOrTablet" v-slot:title>
        <div class="flex justify-start space-x-4 w-full text-left font-manrope">
          <button
            @click="showMap = true"
            class="px-3 py-2 rounded flex items-center justify-center transition-all border text-xs w-1/2 h-12"
            :class="{
              'border-gray-800 text-gray-800': showMap,
              'border-gray-300 text-gray-500 hover:border-gray-400 hover:text-gray-700':
                !showMap,
            }"
          >
            <img
              src="@/assets/svg/icons/icons-pickup.svg"
              :alt="t('PICKUP_ICON')"
              class="w-4 h-4 mr-1"
            />
            {{ t('MAP') }}
          </button>

          <button
            @click="showMap = false"
            class="px-3 py-2 rounded flex items-center justify-center transition-all border text-xs w-1/2 h-12"
            :class="{
              'border-gray-800 text-gray-800': !showMap,
              'border-gray-300 text-gray-500 hover:border-gray-400 hover:text-gray-700':
                showMap,
            }"
          >
            <img
              src="@/assets/svg/icons/icons-list-pickup.svg"
              :alt="t('LIST_ICON')"
              class="w-4 h-4 mr-1"
            />
            {{ t('LIST') }}
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div
          v-if="loading || pending"
          class="flex justify-center items-center mx-auto h-[70vh] w-full md:w-[25vw] md:h-[70vh]"
        >
          <SpinnerFlower :visibility="true" />
        </div>
        <div v-else class="relative">
          <KeepAlive>
            <CheckoutPickUpMap
              v-show="showMap"
              :center="center"
              :zoom="zoom"
              @openInfo="handleOpenInfo($event)"
              :data="stores"
              @select="handleUpdatePickUpAddress($event)"
              @zoomToPoint="handleZoomToStore"
            />
          </KeepAlive>
          <div
            v-show="!showMap"
            class="h-[70vh] w-full md:w-[25vw] md:h-[70vh] overflow-y-auto"
          >
            <ModalCheckoutStoresList
              :stores="stores"
              @select="handleUpdatePickUpAddress($event)"
              @closeList="closeList"
              @zoomToStore="handleZoomToStore"
              :saving="saving"
            />
          </div>

          <div
            class="absolute top-4 left-4 right-4 z-10 flex items-center"
            v-if="!(isMobileOrTablet && !showMap)"
          >
            <div
              class="bg-white w-full relative p-2 md:p-4 border rounded-md shadow-md"
            >
              <div class="absolute right-2 flex items-center gap-1 md:gap-2">
                <button
                  v-if="!isMobileOrTablet"
                  @click="handleListButton"
                  :aria-label="t('OPEN_LIST')"
                >
                  <img
                    src="@/assets/svg/icons/icons-list.svg"
                    class="mt-1 w-5 h-5 md:mt-0 md:w-6 md:h-6"
                    :alt="t('LIST_ICON')"
                  />
                </button>
              </div>

              <input
                :value="address"
                @input="(e) => (address = e.target.value)"
                type="text"
                ref="locationRef"
                placeholder="Introduce dirección"
                class="w-full outline-none text-xxs md:text-xs"
              />
            </div>
          </div>
          <button
            v-if="!(isMobileOrTablet && !showMap)"
            @click="searchByGeoLocation"
            :aria-label="t('SEARCH_LOCATION')"
            class="absolute bottom-4 right-4 z-10 grid place-items-center w-10 h-10 md:w-12 md:h-12 bg-white rounded-md shadow-md"
          >
            <img
              src="@/assets/svg/icons/icons-geolocation.svg"
              class="w-5 h-5 md:w-6 md:h-6"
              :alt="t('GEOLOCATION_ICON')"
            />
          </button>
        </div>
      </template>
    </ModalCourtain>
    <transition name="fade">
      <div
        v-if="showStoresList && !isMobileOrTablet"
        class="fixed top-0 right-0 h-full bg-white shadow-lg w-full sm:w-[28rem] z-[10000]"
      >
        <ModalCheckoutStoresList
          :stores="stores"
          @select="handleUpdatePickUpAddress($event)"
          @closeList="closeList"
          @zoomToStore="handleZoomToStore"
          :saving="saving"
        />
      </div>
    </transition>

    <ModalCheckoutStoresInfo
      v-if="selectedStore"
      :visibility="showStoreInfoModal"
      @select="handleUpdatePickUpAddress($event)"
      :selectedStore="selectedStore"
      @close="showStoreInfoModal = false"
      :showButtons="true"
      :saving="saving"
    />
  </div>
</template>

<script setup>
import { Loader } from '@googlemaps/js-api-loader'

const props = defineProps({
  visibility: Boolean,
  sessionId: {
    type: String,
    required: true,
  },
  methods: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['close', 'refresh'])

const config = useRuntimeConfig()

const { t, locale } = useI18n()
const router = useRouter()
const { addMessage } = useSnackBar()
const { setGeolocation } = useLocalUser()
const { isMobileOrTablet } = useDevice()

const showStoreInfoModal = ref(false)
const selectedStore = ref(null)
const showStoresList = ref(false)
const showMap = ref(true)

const locationRef = ref()
const address = ref('')
const center = ref({ lat: 41.387, lng: 2.1701 })
const zoom = ref(13)

const loading = ref(true)
const saving = ref(false)

const loader = new Loader({
  apiKey: config.public.GOOGLE_MAPS_API_KEY,
  version: 'weekly',
})

// Promise for a specific library
const placesLibrary = await loader.importLibrary('places')

let autocomplete
const fillInAddress = () => {
  address.value = locationRef.value.value
  const place = autocomplete.getPlace()
  if (!place || !place.geometry) return

  const position = {
    coords: {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    },
  }
  updatePosition(position)
}

onMounted(async () => {
  loading.value = false
})

onUnmounted(() => {
  if (autocomplete) {
    google.maps.event.clearInstanceListeners(autocomplete)
  }
})

const {
  data: stores,
  refresh,
  pending,
  error,
} = useLazyAsyncData('stores', () =>
  $fetch('/api/checkout/delivery/stores', {
    method: 'POST',
    body: {
      lat: center.value.lat,
      lng: center.value.lng,
    },
  })
)

watch(
  () => stores.value,
  () => {
    if (stores.value) {
      const methods = props.methods
      const deliveryMethodPickUp = methods.find(
        (method) => method.type == 'CLICK_AND_COLLECT'
      )

      if (!deliveryMethodPickUp) return
      const selectedStore = deliveryMethodPickUp.addresses[0]

      stores.value.forEach((point) => {
        point.selected = false
        if (selectedStore?.id == point?.id) {
          point.selected = true
        }
      })
    }
  }
)

watch(
  () => locationRef.value,
  (newValue) => {
    if (newValue && placesLibrary && !autocomplete) {
      autocomplete = new google.maps.places.Autocomplete(locationRef.value, {
        componentRestrictions: {
          country: [locale.value],
        },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      })
      autocomplete.addListener('place_changed', fillInAddress)
    }
  },
  { immediate: true }
)

const handleOpenInfo = (id) => {
  const point = stores.value.find((point) => point.id == id)
  if (point) {
    selectedStore.value = point
    showStoreInfoModal.value = true
  }
}

const searchByGeoLocation = () => {
  loading.value = true
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(updatePosition, (error) => {})
  } else {
    // TODO // MOSTRAR SNACKBAR CON MENSAJE DE ERROR
  }
  loading.value = false
}

const updatePosition = async (position) => {
  zoom.value = 13
  const newCenter = {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  }

  center.value = newCenter

  setGeolocation(newCenter)

  await refresh()
}

const handleUpdatePickUpAddress = async (addressId) => {
  saving.value = true

  const methods = props.methods
  const pickupMethod = methods.find(
    (method) => method.type == 'CLICK_AND_COLLECT'
  )
  const address = stores.value.find((point) => point.id == addressId)

  const { data, error } = await useAsyncData('selectPickupAddress', () =>
    $fetch('/api/checkout/delivery/select', {
      method: 'POST',
      body: {
        sessionId: props.sessionId,
        method: pickupMethod.id,
        addressId: addressId,
        distance: address.distance,
      },
    })
  )

  if (error.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR'),
    })
  }

  if (data.value.result == 'ok') {
    await refresh()
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('ADDED_PICKUPPOINT'),
    })
  }

  saving.value = false
  emit('close')
  showStoreInfoModal.value = false
  showStoresList.value = false
  emit('refresh')
}

const handleListButton = () => {
  showStoresList.value = !showStoresList.value
}

const cancel = () => {
  address.value = ''
  showStoresList.value = false
  emit('close')
}

const closeList = () => {
  showStoresList.value = false
}

const handleZoomToStore = ({ lat, lng }) => {
  center.value = { lat, lng }
  zoom.value = 18
}
</script>
