<i18n>
  {
    "es": {
        "PRODUCT": "Producto",
        "PRODUCTS": "Productos",
        "PRODUCT_IMAGE": "Imagen de producto",
        "GIFT": "Regalo",
    },
    "en": {
        "PRODUCT": "Product",
        "PRODUCTS": "Products",
        "PRODUCT_IMAGE": "Product image",
        "GIFT": "Gift",
    },
    "fr": {
        "PRODUCT": "Produit",
        "PRODUCTS": "Produits",
        "PRODUCT_IMAGE": "Image du produit",
        "GIFT": "Cadeau",
    },
    "de": {
        "PRODUCT": "Produkt",
        "PRODUCTS": "Produkte",
        "PRODUCT_IMAGE": "Produktbild",
        "GIFT": "Geschenk",
    },
    "it": {
        "PRODUCT": "Prodotto",
        "PRODUCTS": "Prodotti",
        "PRODUCT_IMAGE": "Immagine del prodotto",
        "GIFT": "Regalo",
    },
    "pt": {
        "PRODUCT": "Produto",
        "PRODUCTS": "Produtos",
        "PRODUCT_IMAGE": "Imagem do produto",
        "GIFT": "Presente",
    }
}
</i18n>

<template>
  <div class="w-full flex items-center pr-2 py-2">
    <img
      :src="product?.img"
      :alt="t('PRODUCT_IMAGE')"
      class="h-16 w-20 md:h-20 md:w-24 object-contain mx-auto mr-1 mt-1"
    />

    <div class="flex w-full justify-between items-center">
      <div class="space-y-1 md:space-y-2 w-3/6 md:w-full">
        <p class="text-xxs uppercase font-bodoni tracking-wide">
          {{ product?.brand }}
        </p>
        <p class="text-xs font-semibold tracking-wider">
          {{ product?.name ?? product?.short_name }}
        </p>
        <p
          v-if="!product?.is_gift"
          class="text-xxs md:text-xs text-sanremo-gray"
        >
          {{ product?.presentation }}
        </p>
      </div>

      <div
        class="mt-1 md:mt-2 space-y-1 md:space-y-2 flex flex-col items-start text-xxs text-sanremo-gray"
      >
        <div
          v-if="product?.price && !product?.is_gift"
          class="flex items-center justify-center text-xs md:text-sm font-bold"
        >
          <p class="mr-1">{{ product?.qty }} x</p>
          <p>{{ formatPrice(product?.price) }}€</p>
        </div>
        <div
          class="flex items-center justify-center gap-x-2"
          v-if="!product?.is_gift"
        >
          <p
            v-if="product?.pvp && product?.pvp !== product?.price"
            class="tracking-wider text-xs md:text-sm"
            :class="product?.badge != '0%' ? 'line-through' : ''"
          >
            {{ formatPrice(product?.pvp) }}€
          </p>
          <p
            class="flex h-4 w-8 text-xxs bg-sanremo-black text-white rounded items-center justify-center"
            v-if="
              product?.badge &&
              product?.price_policy !== 'ONLY_EFFECTIVE_PRICE' &&
              product?.price_policy !== 'NO_DISCOUNT_BADGE'
            "
            :class="product?.badge === '0%' ? 'hidden' : 'block'"
          >
            {{ product?.badge }}
          </p>
          <div v-else class="h-4 w-8"></div>
        </div>
        <p
          v-if="!product?.is_gift"
          class="text-xs md:text-sm tracking-wide font-semibold text-sanremo-black"
        >
          {{ formatPrice(product?.subtotal) }}€
        </p>
        <p
          v-else
          class="text-xs md:text-sm tracking-wide whitespace-nowrap font-semibold text-sanremo-black"
        >
          {{ product?.qty + 'x ' + t('GIFT') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatPrice } from '@/utils/helpers'
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n({
  useScope: 'local',
})
</script>
