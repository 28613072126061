<i18n>
  {
    "es": {
      "ADDED_PICKUPPOINT": "Seleccionado punto de recogida",
      "ERROR": "Error al añadir punto de recogida",
      "GEOLOCATION_ICON": "Icono de geolocalización",
      "LIST_ICON": "Icono de lista",
      "SEARCH_LOCATION": "Buscar por ubicación",
      "OPEN_LIST": "Abrir lista de puntos de recogida",
      "MAP": "Mapa",
      "LIST": "Lista",
      "PICKUP_ICON": "Icono de localización",
    },
    "en": {
      "ADDED_PICKUPPOINT": "Pickup point selected",
      "ERROR": "Error adding pickup point",
      "GEOLOCATION_ICON": "Geolocation icon",
      "LIST_ICON": "List icon",
      "SEARCH_LOCATION": "Search by location",
      "OPEN_LIST": "Open pickup points list",
      "MAP": "Map",
      "LIST": "List",
      "PICKUP_ICON": "Location icon",
    },
    "fr": {
      "ADDED_PICKUPPOINT": "Point de collecte sélectionné",
      "ERROR": "Erreur lors de l'ajout du point de collecte",
      "GEOLOCATION_ICON": "Icône de géolocalisation",
      "LIST_ICON": "Icône de liste",
      "SEARCH_LOCATION": "Rechercher par emplacement",
      "OPEN_LIST": "Ouvrir la liste des points de collecte",
      "MAP": "Carte",
      "LIST": "Liste",
      "PICKUP_ICON": "Icône de localisation",
    },
    "it": {
      "ADDED_PICKUPPOINT": "Punto di ritiro selezionato",
      "ERROR": "Errore durante l'aggiunta del punto di ritiro",
      "GEOLOCATION_ICON": "Icona di geolocalizzazione",
      "LIST_ICON": "Icona elenco",
      "SEARCH_LOCATION": "Cerca per posizione",
      "OPEN_LIST": "Apri elenco punti di ritiro",
      "MAP": "Mappa",
      "LIST": "Elenco",
      "PICKUP_ICON": "Icona di localizzazione",
    },
    "de": {
      "ADDED_PICKUPPOINT": "Abholpunkt ausgewählt",
      "ERROR": "Fehler beim Hinzufügen des Abholpunkts",
      "GEOLOCATION_ICON": "Geolokalisierungssymbol",
      "LIST_ICON": "Listensymbol",
      "SEARCH_LOCATION": "Nach Standort suchen",
      "OPEN_LIST": "Liste der Abholpunkte öffnen",
      "MAP": "Karte",
      "LIST": "Liste",
      "PICKUP_ICON": "Standortsymbol",
    },
    "pt": {
      "ADDED_PICKUPPOINT": "Ponto de coleta selecionado",
      "ERROR": "Erro ao adicionar ponto de coleta",
      "GEOLOCATION_ICON": "Ícone de geolocalização",
      "LIST_ICON": "Ícone de lista",
      "SEARCH_LOCATION": "Pesquisar por localização",
      "OPEN_LIST": "Abrir lista de pontos de coleta",
      "MAP": "Mapa",
      "LIST": "Lista",
      "PICKUP_ICON": "Ícone de localização",
    }
  }
</i18n>

<template>
  <div>
    <ModalCourtain :visibility="visibility" @close="cancel">
      <template v-if="isMobileOrTablet" v-slot:title>
        <div class="flex justify-start space-x-4 w-full text-left font-manrope">
          <button
            @click="showMap = true"
            class="px-3 py-2 rounded flex items-center justify-center transition-all border text-xs w-1/2 h-12"
            :class="{
              'border-gray-800 text-gray-800': showMap,
              'border-gray-300 text-gray-500 hover:border-gray-400 hover:text-gray-700':
                !showMap,
            }"
          >
            <img
              src="@/assets/svg/icons/icons-pickup.svg"
              :alt="t('PICKUP_ICON')"
              class="w-4 h-4 mr-1"
            />
            {{ t('MAP') }}
          </button>

          <button
            @click="showMap = false"
            class="px-3 py-2 rounded flex items-center justify-center transition-all border text-xs w-1/2 h-12"
            :class="{
              'border-gray-800 text-gray-800': !showMap,
              'border-gray-300 text-gray-500 hover:border-gray-400 hover:text-gray-700':
                showMap,
            }"
          >
            <img
              src="@/assets/svg/icons/icons-list-pickup.svg"
              :alt="t('LIST_ICON')"
              class="w-4 h-4 mr-1"
            />
            {{ t('LIST') }}
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div
          v-show="loading || pending"
          class="flex justify-center items-center mx-auto h-[70vh] w-full md:w-[25vw] md:h-[70vh]"
        >
          <SpinnerFlower :visibility="true" />
        </div>
        <div v-show="!loading && !pending" class="relative">
          <KeepAlive>
            <CheckoutPickUpMap
              v-show="showMap"
              :center="center"
              :zoom="zoom"
              @openInfo="handleOpenInfo($event)"
              :data="pickUpPoints"
              @select="handleUpdatePickUpAddress($event)"
              @zoomToPickUpPoint="handleZoomToPickUpPoint"
            />
          </KeepAlive>
          <div
            v-show="!showMap"
            class="h-[70vh] w-full md:w-[25vw] md:h-[70vh] overflow-y-auto"
          >
            <ModalCheckoutPickuppointsList
              :pickUpPoints="pickUpPoints"
              @select="handleUpdatePickUpAddress($event)"
              @closeList="closeList"
              @zoomToPickUpPoint="handleZoomToPickUpPoint"
              :saving="saving"
            />
          </div>

          <div
            class="absolute top-4 left-4 right-4 z-10 flex items-center"
            v-show="!(isMobileOrTablet && !showMap)"
          >
            <div
              class="bg-white w-full relative p-2 md:p-4 border rounded-md shadow-md"
            >
              <div class="absolute right-2 flex items-center gap-1 md:gap-2">
                <button
                  v-if="!isMobileOrTablet"
                  @click="handleListButton"
                  :aria-label="t('OPEN_LIST')"
                >
                  <img
                    src="@/assets/svg/icons/icons-list.svg"
                    class="mt-1 w-5 h-5 md:mt-0 md:w-6 md:h-6"
                    :alt="t('LIST_ICON')"
                  />
                </button>
              </div>

              <input
                :value="address"
                @input="(e) => (address = e.target.value)"
                type="text"
                ref="locationRef"
                placeholder="Introduce dirección"
                class="w-full outline-none text-xxs md:text-xs"
              />
            </div>
          </div>
          <button
            v-if="!(isMobileOrTablet && !showMap)"
            @click="searchByGeoLocation"
            :aria-label="t('SEARCH_LOCATION')"
            class="absolute bottom-4 right-4 z-10 grid place-items-center w-10 h-10 md:w-12 md:h-12 bg-white rounded-md shadow-md"
          >
            <img
              src="@/assets/svg/icons/icons-geolocation.svg"
              class="w-5 h-5 md:w-6 md:h-6"
              :alt="t('GEOLOCATION_ICON')"
            />
          </button>
        </div>
      </template>
    </ModalCourtain>
    <transition name="fade">
      <div
        v-if="showPickUpList && !isMobileOrTablet"
        class="fixed top-0 right-0 h-full bg-white shadow-lg w-full sm:w-[28rem] z-[10000]"
      >
        <ModalCheckoutPickuppointsList
          :pickUpPoints="pickUpPoints"
          @select="handleUpdatePickUpAddress($event)"
          @closeList="closeList"
          @zoomToPickUpPoint="handleZoomToPickUpPoint"
          :saving="saving"
        />
      </div>
    </transition>

    <ModalCheckoutPickuppointsInfo
      v-if="selectedPickUpPoint"
      :visibility="showPickupInfoModal"
      @select="handleUpdatePickUpAddress($event)"
      :selectedPickUpPoint="selectedPickUpPoint"
      @close="showPickupInfoModal = false"
      :showButtons="true"
      :saving="saving"
    />
  </div>
</template>

<script setup>
import { Loader } from '@googlemaps/js-api-loader'

const props = defineProps({
  visibility: Boolean,
  sessionId: {
    type: String,
    required: true,
  },
  methods: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['close', 'refresh'])

const config = useRuntimeConfig()

const { t, locale } = useI18n()
const { addMessage } = useSnackBar()
const { geolocation, setGeolocation } = useLocalUser()
const { isMobileOrTablet } = useDevice()

const showPickupInfoModal = ref(false)
const selectedPickUpPoint = ref(null)
const showPickUpList = ref(false)
const showMap = ref(true)

const locationRef = ref()
const address = ref('')
const center = ref(geolocation.value ?? { lat: 41.387, lng: 2.1701 })
const zoom = ref(13)

const loading = ref(true)
const saving = ref(false)

const loader = new Loader({
  apiKey: config.public.GOOGLE_MAPS_API_KEY,
  version: 'weekly',
})

// Promise for a specific library
const placesLibrary = await loader.importLibrary('places')

let autocomplete
const fillInAddress = () => {
  address.value = locationRef.value.value
  const place = autocomplete.getPlace()
  if (!place || !place.geometry) return

  const position = {
    coords: {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    },
  }
  updatePosition(position)
}

onMounted(async () => {
  loading.value = false
})

onUnmounted(() => {
  if (autocomplete) {
    google.maps.event.clearInstanceListeners(autocomplete)
  }
})

const {
  data: pickUpPoints,
  refresh,
  pending,
  error,
} = useLazyAsyncData('pickUpPoints', () =>
  $fetch('/api/checkout/delivery/pickuppoints', {
    method: 'POST',
    body: {
      lat: center.value.lat,
      lng: center.value.lng,
    },
  })
)

watch(
  () => pickUpPoints.value,
  () => {
    if (pickUpPoints.value) {
      const methods = props.methods
      const deliveryMethodPickUp = methods.find(
        (method) => method.type == 'PICKUP_POINT'
      )

      if (!deliveryMethodPickUp) return
      const selectedPickUpPoint = deliveryMethodPickUp.addresses[0]

      pickUpPoints.value.forEach((point) => {
        point.selected = false
        if (selectedPickUpPoint?.id == point?.id) {
          point.selected = true
        }
      })
    }
  }
)

watch(
  () => locationRef.value,
  (newValue) => {
    if (newValue && placesLibrary && !autocomplete) {
      autocomplete = new placesLibrary.Autocomplete(locationRef.value, {
        componentRestrictions: {
          country: [locale.value],
        },
        fields: ['geometry'],
        types: ['address'],
      })
      autocomplete.addListener('place_changed', fillInAddress)
    }
  },
  { immediate: true }
)

const handleOpenInfo = (id) => {
  const point = pickUpPoints.value.find((point) => point.id == id)
  if (point) {
    selectedPickUpPoint.value = point
    showPickupInfoModal.value = true
  }
}

const searchByGeoLocation = () => {
  loading.value = true
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(updatePosition, (error) => {})
  } else {
    // TODO // MOSTRAR SNACKBAR CON MENSAJE DE ERROR
  }
  loading.value = false
}

const updatePosition = async (position) => {
  zoom.value = 13
  const newCenter = {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  }

  center.value = newCenter

  setGeolocation(newCenter)

  await refresh()
}

const handleUpdatePickUpAddress = async (addressId) => {
  saving.value = true

  const methods = props.methods
  const pickupMethod = methods.find((method) => method.type == 'PICKUP_POINT')
  const address = pickUpPoints.value.find((point) => point.id == addressId)

  const { data, error } = await useAsyncData('selectPickupAddress', () =>
    $fetch('/api/checkout/delivery/select', {
      method: 'POST',
      body: {
        sessionId: props.sessionId,
        method: pickupMethod.id,
        addressId: addressId,
        distance: address.distance,
      },
    })
  )

  if (error.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR'),
    })
  }

  if (data.value.result == 'ok') {
    await refresh()
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('ADDED_PICKUPPOINT'),
    })
  }

  saving.value = false
  emit('close')
  showPickupInfoModal.value = false
  showPickUpList.value = false
  emit('refresh')
}

const handleListButton = () => {
  showPickUpList.value = !showPickUpList.value
}

const cancel = () => {
  address.value = ''
  showPickUpList.value = false
  emit('close')
}

const closeList = () => {
  showPickUpList.value = false
}

const handleZoomToPickUpPoint = ({ lat, lng }) => {
  center.value = { lat, lng }
  zoom.value = 18
}
</script>
