<i18n>
    {
      "es": {
          "ARTICLES": "artículo | artículos",
          "ORDER_SUMMARY": "Resumen del pedido",
      },
      "en": {
        "ARTICLES": "article | articles",
        "ORDER_SUMMARY": "Order summary",
      },
      "fr": {
        "ARTICLES": "article | articles",
        "ORDER_SUMMARY": "Récapitulatif de la commande",
      },
      "de": {
        "ARTICLES": "artikel | artikel",
        "ORDER_SUMMARY": "Bestellübersicht",
      },
      "it": {
        "ARTICLES": "articolo | articoli",
        "ORDER_SUMMARY": "Riepilogo ordine",
      },
      "pt": {
        "ARTICLES": "artigo | artigos",
        "ORDER_SUMMARY": "Resumo do pedido",
      }
  }
</i18n>

<template>
  <div class="w-full">
    <div class="w-full flex justify-between items-center">
      <div v-if="loading" class="w-full">
        <div class="h-5 w-3/5 md:w-2/6 md:mt-1 rounded-lg bg-[#d8d8d8]"></div>
      </div>
      <h2 v-else class="w-full text-base sm:text-base md:text-xl">
        {{ t('ORDER_SUMMARY') }}
      </h2>

      <div
        v-if="loading"
        class="mt-1 h-4 w-3/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
      ></div>
      <button
        v-else
        @click="toggleCollapse"
        class="mt-0.5 flex items-center justify-end space-x-1 w-full border-gray-200"
      >
        <div class="flex justify-start items-center space-x-3">
          <span class="text-sanremo-black font-semibold text-xs md:text-sm">
            {{ total }}{{ currency.symbol }}
          </span>
        </div>

        <img
          src="@/assets/svg/icons/icons-arrow-down.svg"
          alt="icono de flecha hacia abajo"
          class="expand-icon icon icon--SVG_15 transition-transform duration-300"
          :class="{ 'rotate-180': collapse }"
        />
      </button>
    </div>

    <div
      v-if="loading"
      class="mt-1 h-3 w-2/12 md:w-1/12 rounded-lg bg-[#d8d8d8]"
    ></div>
    <p v-else class="text-sanremo-gray text-xxs md:text-xs tracking-wide">
      {{ data?.find(Boolean).items?.length }}
      {{ t('ARTICLES', data?.find(Boolean).items?.length) }}
    </p>

    <div
      :class="collapse ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'"
      class="overflow-hidden transition-all duration-300 ease-in-out"
    >
      <div
        v-if="loading"
        class="w-full md:max-w-1400 max-h-[310px] md:max-h-[440px] mt-12 pb-4 md:pb-8 mb-10 md:mb-9"
      >
        <div class="flex flex-col items-start w-full space-y-4">
          <div class="w-full flex justify-between items-center">
            <div
              class="w-full flex justify-start items-start space-x-3 md:space-x-4 pl-2"
            >
              <div
                class="h-16 w-20 md:w-24 md:h-20 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="w-full flex flex-col justify-start items-start mt-1 md:mt-2"
              >
                <div
                  class="h-4 w-11/12 md:w-6/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-8/12 md:w-3/12 rounded-lg bg-[#d8d8d8]"
                ></div>
              </div>
            </div>
            <div class="w-full flex items-end flex-col pr-12 md:pr-16">
              <div class="h-4 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
            </div>
          </div>

          <div class="w-full flex justify-between items-center">
            <div
              class="w-full flex justify-start items-start space-x-3 md:space-x-4 pl-2"
            >
              <div
                class="h-16 w-20 md:w-24 md:h-20 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="w-full flex flex-col justify-start items-start mt-1 md:mt-2"
              >
                <div
                  class="h-4 w-11/12 md:w-6/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-8/12 md:w-3/12 rounded-lg bg-[#d8d8d8]"
                ></div>
              </div>
            </div>
            <div class="w-full flex items-end flex-col pr-12 md:pr-16">
              <div class="h-4 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
            </div>
          </div>

          <div class="w-full flex justify-between items-center">
            <div
              class="w-full flex justify-start items-start space-x-3 md:space-x-4 pl-2"
            >
              <div
                class="h-16 w-20 md:w-24 md:h-20 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="w-full flex flex-col justify-start items-start mt-0 md:mt-2"
              >
                <div
                  class="h-4 w-11/12 md:w-6/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-8/12 md:w-3/12 rounded-lg bg-[#d8d8d8]"
                ></div>
              </div>
            </div>
            <div class="w-full flex items-end flex-col pr-12 md:pr-16">
              <div class="h-4 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
            </div>
          </div>

          <div class="w-full flex justify-between items-center">
            <div
              class="w-full flex justify-start items-start space-x-3 md:space-x-4 pl-2"
            >
              <div
                class="h-16 w-20 md:w-24 md:h-20 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="w-full flex flex-col justify-start items-start mt-0 md:mt-2"
              >
                <div
                  class="h-4 w-11/12 md:w-6/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-1 md:mt-3 h-3 w-8/12 md:w-3/12 rounded-lg bg-[#d8d8d8]"
                ></div>
              </div>
            </div>
            <div class="w-full flex items-end flex-col pr-12 md:pr-16">
              <div class="h-4 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="mt-2 md:mt-3 h-3 w-4/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <CheckoutProductContainer
        v-else
        class="w-full"
        :shipments="data"
        :shipmentSelected="shipmentSelected"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
  },
  shipmentSelected: {
    type: String,
  },
  total: {
    type: Number,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
  loading: Boolean,
})

const { t } = useI18n({
  useScope: 'local',
})

const collapse = ref(true)

const toggleCollapse = () => {
  collapse.value = !collapse.value
}
</script>
